
.result-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 0 0 60%;
	padding: 15px;
	text-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
}

	.result {
		display: none;
		font-weight: 600;
		margin-bottom: 20px;
	}

	.result-detail {
		width: 100%;
		display: none;
	}

	.result-detail li {
		width: 100%;
		font-weight: 400;
		text-align: right;
		margin-bottom: .5em;
	}

		.result-val {
			font-weight: 600;
			padding-left: 10px;
			display: inline-block;
			width: 50%;
			text-align: left;
		}

		.success {
			font-size: 36px;
			color: #90ee90;
		}

		.error {
			font-size: 36px;
			color: #ffc7c7;
		}

		.result-detail .success,
		.result-detail .error {
			font-size: 16px;
			font-weight: 600;
		}

@media only screen and (max-width: 566.98px) {

	.result-wrapper {
		align-items: flex-start;
	}

	.result-detail li {
		text-align: left;
		margin-bottom: 1.5em;
	}

	.result-val {
		display: block;
		width: 100%;
	}

	.success,
	.error {
		font-size: 26px;
	}
}