.box-outer {
	min-height: 500px;
	width: 100%;
	max-width: 700px;
	padding: 20px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.box-inner {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	width: 100%;
}

.box-title-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
  flex: 0 0 20%;
	margin-bottom: 80px;
}

.box-title {
	font-size: 46px;
	line-height: 1.3em;
	font-weight: 400;
	text-align: center;
}

.ip-form-wrapper {
	flex: 0 0 20%;
	padding: 0 15px;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
}

.ip-form {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

	.ip-form input {
		font-family: 'Rubik', sans-serif;
		display: block;
		color: #888;
		outline: none;
		padding: 10px;
		font-size: 16px;
		background-color: #efefef;
		border-radius: 5px;
		border: none;
		width: calc(75% - 15px);
	}
	
	.ip-form label {
		display: none;
	}
	
	.ip-form input[type="submit"] {
		background-color: #fec800;
		color: #fff;
		width: 25%;
		padding: 8px 15px;
		cursor: pointer;
		font-size: 20px;
		font-weight: 500;
		text-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
		border-radius: 5px;
		margin-bottom: 0;
		transition: background-color .3s ease-in-out;
		-webkit-appearance: none;
	}

	.ip-form input[type="submit"]:hover {
		background-color: #fec700de;
		transition: background-color .3s ease-in-out;
	}


@media only screen and (max-width: 566.98px) {

	.box-title {
		font-size: 26px;
	}

	.ip-form {
		flex-direction: column;
	}

		.ip-form input {
			width: 100%;
			margin-bottom: 40px;
		}

		.ip-form input[type="submit"] {
			width: 100%;
		}
}