/* rubik-300 - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-300italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 300;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-regular - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 400;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-500 - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-500italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 500;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-600 - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 600;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-600italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 600;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-700 - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-700italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 700;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-800 - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 800;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-800italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 800;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-900 - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 900;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  
  /* rubik-900italic - arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 900;
	src: url('https://res.onoffice.de/template/fonts/rubik/rubik-v28-arabic_cyrillic_cyrillic-ext_hebrew_latin_latin-ext-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  

* {
	box-sizing: border-box;
	margin: 0;
	line-height: 1.6;
}

body {
	font-family: 'Rubik', sans-serif;
	background-color: #3F9DE4;
}

ul {
	list-style: none;
	text-align: left;
	padding: 0;
	margin: 0;
}

.App {
	color: #fff;
	height: 100vmin;
	text-align: center;
}

.header-logo {
	height: 40px;
	margin: 40px 0;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 160px;
}

.link {
	color: #46acfa;
}

@media only screen and (max-width: 566.98px) {
	.header {
		margin-bottom: 40px;
	}
}